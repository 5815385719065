const cognitoSettings = {
  REGION: "us-west-2",
  USER_POOL_ID: "us-west-2_Jy8an7NMM",
  APP_CLIENT_ID: "4365dmed6gk235mimvjon2mct1",
  IDENTITY_POOL_ID: "us-west-2:11a96a66-3170-409c-bad3-bb18d5e3dc3f",
}

const dev = {
  cognito: cognitoSettings,
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://dev.api.demo.mehkey.com"
  },
  s3: {
    BUCKET: "mkdemoapp-dev",
    REGION: "us-west-2",
  }
}

const prod =  {
  cognito: cognitoSettings,
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://api.demo.mehkey.com",
  },
  s3: {
    BUCKET: "mkdemoapp",
    REGION: "us-west-2",
  }
}

export const APINAME="photos"

export default process.env.NODE_ENV === "development" ? dev : prod;
