import React from 'react'

const Footer = () => (
    <footer className="footer">
      <div className="container">
        <p>© 2022 Mehkey Demo</p>
      </div>
    </footer>
);

export default Footer
